import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './NoSearchResultsMaybe.module.scss';

import { default as imgNoResultsPerson } from '../../../assets/images/imgNoResultsPerson.svg';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <div className={css.row}>
        <div className={css.col12}>
          <img src={imgNoResultsPerson} />
        </div>
        <div className={css.col12}>
          <span className={css.noResultsText}>
            <FormattedMessage id="SearchPage.noResults" />
          </span>
        </div>
        <div className={css.col12}>
          {hasSearchParams ? (
            <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
              <FormattedMessage id={'SearchPage.resetAllFilters'} />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
